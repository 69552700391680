<script lang="ts">
import Vue from "vue";
import { getProps, getData, manualVehicleInstance, mappedComputed } from "./manualVehicleInstance";
import { continueClicked, changeVehicle } from "./methods";
import { Fragment } from "vue-frag";
import BlockButton from "@/components/shared/blocks/button.vue";
import BlockPadding from "@/components/shared/blocks/padding.vue";
import BlockDatePicker from "@/components/shared/blocks/datepicker.vue";
import { getClaimDataService } from "@/helpers/vue";
export default Vue.extend({
  name: "BlockManualVehicle",
  components: {
    Fragment,
    BlockButton,
    BlockPadding,
    BlockDatePicker,
  },
  props: {
    ...getProps(),
  },
  computed: {
    ...mappedComputed(),
  },
  data() {
    return {
      ...getData(this),
    };
  },
  methods: {
    continueClicked(currentView: manualVehicleInstance["currentView"]) {
      continueClicked(this, currentView);
    },
    changeVehicle() {
      changeVehicle(this);
    },
  },
  async mounted() {
    this.userId = this.userService.getUserId();
    getClaimDataService<manualVehicleInstance>(this as any);

    let { data: funnelVariables } = await this.funnelsService.getFunnelVariables(this.funnelId);
    this.funnelVariables = funnelVariables;
  },
});
</script>
<style scoped>
.error {
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #e13f45 !important;
  transition: 0.25s;
}
.uppercase {
  text-transform: uppercase;
}
.reg-input-selected {
  font-size: 20px;
  font-weight: 500;
  color: #000;
}
.custom-input {
  border-right-width: 0px !important;
  border-radius: 5px;
  border-width: 2px !important;
  border-color: #ced4da !important;
  transition: 0.25s;
}

.border-color {
  border-color: #ced4da !important;
}
.reg-input-selected:focus {
  border-color: var(--primary) !important;
}

.is-invalid {
  border-radius: 0.25rem;
  border-width: 2px !important;
  border-color: var(--danger) !important;
  transition: 0.25s;
}
</style>
<template>
  <Fragment>
    <BlockPadding :padding="padding" />
    <div>
      <!-- View 1: Registration Input -->
      <template v-if="currentView === 'regInput'">
        <div>
          <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Registration Number</h5>
          <p class="mb-1 text-grey-700">Please enter your vehicle registration number</p>

          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text class="justify-content-center reg-input-selected custom-input">
                <img :src="require('@/assets/images/GB.png')" style="width: 30px" alt="GB" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              class="border reg-input-selected custom-input uppercase"
              size="lg"
              maxLength="15"
              v-model="store.vrm"
              :state="uiState.vrm"
              :invalidFeedback="invalidFeedbacks.vrm"
              placeholder="Example: AB12 CDE"
              @update="uiState.vrm = null"
            ></b-form-input>
          </b-input-group>

          <template v-if="!uiState.vrm">
            <b-form-invalid-feedback :state="uiState.vrm" id="input-live-feedback">
              {{ invalidFeedbacks.vrm }}
            </b-form-invalid-feedback>
          </template>

          <BlockButton
            @clicked="continueClicked(currentView)"
            v-bind="BlockButtonInputs.regInput"
            v-bind.sync="BlockButtonInputs.regInput"
          />
        </div>
      </template>

      <!-- View 2: Make and Model-->
      <!-- TODO: Seek to use dropdown.vue so we can accept other input -->

      <template v-if="currentView === 'makeModel'">
        <div>
          <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
            <b-card-body style="padding-bottom: 0.25rem !important">
              <h4 class="text-grey-700 font-weight-semibold">Your Vehicle</h4>
              <h4 class="text-grey-900">
                <b
                  >Registration: <span class="uppercase">{{ store.vrm }}</span></b
                >
              </h4>

              <h5 class="font-weight-semibold" role="button" @click="changeVehicle">
                <span :style="theme.textColourStyles">Click here to change your vehicle</span>
              </h5>
            </b-card-body>
          </b-card>
          <BlockPadding :padding="padding" />
          <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Make</h5>
          <p class="mb-1 text-grey-700">Please select the make of your vehicle</p>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text class="justify-content-center custom-input">
                <div style="width: 30px" alt="GB" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-select
              class="border border-color border-thick"
              size="lg"
              v-model="store.make"
              :state="uiState.make"
              :invalidFeedback="invalidFeedbacks.make"
              @change="uiState.make = null"
              placeholder="Select vehicle make"
            >
              <option :value="null" disabled>Select vehicle make</option>
              <option v-for="(make, index) in makeList" :key="index" :value="make">{{ make }}</option>
            </b-form-select>
          </b-input-group>

          <template v-if="uiState.make === false">
            <b-form-invalid-feedback :state="uiState.make" id="input-live-feedback">
              {{ invalidFeedbacks.make }}
            </b-form-invalid-feedback>
          </template>

          <template v-if="store.make === 'Other'">
            <BlockPadding :padding="padding" />
            <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Make</h5>
            <p class="mb-1 text-grey-700">Please enter the make of your vehicle</p>
            <b-input-group>
              <b-input-group-prepend>
                <b-input-group-text class="justify-content-center custom-input">
                  <div style="width: 30px" alt="GB" />
                </b-input-group-text>
              </b-input-group-prepend>
              <b-form-input
                class="border custom-input"
                size="lg"
                v-model="store.otherMake"
                :state="uiState.otherMake"
                :invalidFeedback="invalidFeedbacks.otherMake"
                @change="uiState.otherMake = null"
                placeholder="Enter vehicle make"
              ></b-form-input>
            </b-input-group>
          </template>

          <template v-if="uiState.otherMake === false">
            <b-form-invalid-feedback :state="uiState.otherMake" id="input-live-feedback">
              {{ invalidFeedbacks.otherMake }}
            </b-form-invalid-feedback>
          </template>

          <BlockPadding :padding="padding" />
          <h5 class="mb-0 font-weight-semibold text-grey-900">Vehicle Model</h5>
          <p class="mb-1 text-grey-700">Please enter the model of your vehicle</p>
          <b-input-group>
            <b-input-group-prepend>
              <b-input-group-text class="justify-content-center custom-input">
                <div style="width: 30px" alt="GB" />
              </b-input-group-text>
            </b-input-group-prepend>
            <b-form-input
              class="border custom-input"
              size="lg"
              v-model="store.model"
              :state="uiState.model"
              :invalidFeedback="invalidFeedbacks.model"
              @change="uiState.model = null"
              placeholder="Enter vehicle model"
            ></b-form-input>
          </b-input-group>

          <template v-if="uiState.model === false">
            <b-form-invalid-feedback :state="uiState.model" id="input-live-feedback">
              {{ invalidFeedbacks.model }}
            </b-form-invalid-feedback>
          </template>

          <h5 class="font-weight-semibold mt-2" role="button" @click="changeVehicle">
            <span :style="theme.textColourStyles">Click here to change your vehicle</span>
          </h5>

          <!-- Continue button: add functionality - if valid change view-->
          <BlockButton
            @clicked="continueClicked(currentView)"
            v-bind="BlockButtonInputs.makeModel"
            v-bind.sync="BlockButtonInputs.makeModel"
          />
        </div>
      </template>

      <!-- View 3: Purchase-->
      <template v-if="currentView === 'purchaseDate'">
        <div>
          <b-card class="mb-0" no-body style="box-shadow: 0 0rem 0.25rem rgb(0 0 0 / 15%)">
            <b-card-body style="padding-bottom: 0.25rem !important">
              <h4 class="text-grey-700 font-weight-semibold">Your Vehicle</h4>
              <h4 class="text-grey-900 uppercase">
                <b>{{ store.make === "Other" ? store.otherMake : store.make }}</b
                >, <b>{{ store.model }}</b
                >, <b>{{ store.vrm }}</b>
              </h4>

              <h5 class="font-weight-semibold" role="button" @click="changeVehicle">
                <span :style="theme.textColourStyles">Click here to change your vehicle</span>
              </h5>
            </b-card-body>
          </b-card>
          <BlockPadding :padding="padding" />
          <!-- purchaseDate -->
          <h5 class="mb-0 font-weight-semibold text-grey-900">Purchase Date</h5>
          <p class="mb-1 text-grey-700">Please select the date when you purchased the vehicle</p>
          <BlockDatePicker
            v-bind="BlockInputs.purchaseDatePicker"
            v-bind.sync="BlockInputs.purchaseDatePicker"
            :answer.sync="store.purchaseDate"
          />

          <template v-if="!uiState.purchaseDate">
            <b-form-invalid-feedback :state="uiState.purchaseDate" id="input-live-feedback">
              {{ invalidFeedbacks.purchaseDate }}
            </b-form-invalid-feedback>
          </template>

          <BlockButton
            @clicked="continueClicked(currentView)"
            v-bind="BlockButtonInputs.purchaseDate"
            v-bind.sync="BlockButtonInputs.purchaseDate"
            :isProcessing="loaders.next"
          />
        </div>
      </template>
    </div>
  </Fragment>
</template>
