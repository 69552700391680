import { BlockMotorSpecsInstance } from "./BlockMotorSpecs";
import { ClaimsGateMotorSpecsGetVehicleResponse, State, PriceEstimationRequest } from "@claimsgate/core-types";
import {
  ClaimsGateErrors,
  ClaimsGateFunctionReturnedError,
  Milberg,
  Tlw,
  Quantum,
  Lanier,
  Provenio,
  getClaimsByFunnelId,
  SlaterGordon,
  GilsonGrey,
  getClaim,
  Dgm,
} from "@claimsgate/core";
import { DateTime } from "luxon";
import { MotorSpecsPartialVehicleStore } from "./types";

import omitBy from "lodash.omitby";
import isNil from "lodash.isnil";
import { reject } from "@/helpers/vue";
import { onCallGateway } from "@/helpers/ClaimsGate/onCallGateway";
import { fireMotorSpecsApiErrorEvent } from "./sentry";
import { JONES_WHYTE_FUNNELS } from "@/components/claimant/form/next";
import { getFirebaseBackend } from "@/authUtils";

/** Methods to be consumed by Vue instance */
export const methods = {
  searchVehicle,
  submitVehicleConfirmation,
  submitKeeperSelect,
  submitOwnershipDate,
  displayHelpModal,
  setValidationInvalidFeedback,
  notMyVehicle,
};

export function displayHelpModal(state: BlockMotorSpecsInstance) {
  state.$bvModal.show("helpModal");
}

/** When the user selects not my vehicle */
export function notMyVehicle(state: BlockMotorSpecsInstance) {
  resetToSearchVehicleView(state);
}

export async function submitOwnershipDate(state: BlockMotorSpecsInstance) {
  state.BlockInputs.submitOwnershipDateButton.isProcessing = true;
  if (!validateOwnershipDate(state)) {
    state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
    return;
  }

  // Search for the vehicle with a forced lookup on the historical vin
  await searchVehicle(state, true);

  state.BlockInputs.submitOwnershipDateButton.isProcessing = false;
}

function calculateDepreciatedPrice(purchasePrice: number, months: number) {
  if (months === 0) {
    return [purchasePrice, 0];
  }

  const annualDepreciationRate1 = 0.2; // First year depreciation
  const annualDepreciationRate2 = 0.15; // Depreciation for the next three years
  const annualDepreciationRate3 = 0.08; // Depreciation after the fourth year

  const years = months / 12;
  const initialYears = Math.min(years, 1);
  const middleYears = Math.min(Math.max(years - 1, 0), 3);
  const laterYears = Math.max(years - 4, 0);

  const initialDepreciation = Math.pow(1 - annualDepreciationRate1, initialYears);
  const middleDepreciation = Math.pow(1 - annualDepreciationRate2, middleYears);
  const laterDepreciation = Math.pow(1 - annualDepreciationRate3, laterYears);

  const estimatedDepreciatedPrice = purchasePrice * initialDepreciation * middleDepreciation * laterDepreciation;
  const estimatedDepreciatedAmount = purchasePrice - estimatedDepreciatedPrice;
  if (
    estimatedDepreciatedAmount < 0 ||
    isNaN(estimatedDepreciatedAmount) ||
    estimatedDepreciatedPrice < 0 ||
    isNaN(estimatedDepreciatedPrice)
  ) {
    throw new Error("Estimated depreciated amount is less than 0 or NAN");
  }
  return [estimatedDepreciatedPrice, estimatedDepreciatedAmount];
}

/** Sets invalid feedback which has been fed from a page level validation onto the block */
export function setValidationInvalidFeedback(
  state: BlockMotorSpecsInstance,
  invalidFeedback: string,
  stayOnPage = false
) {
  if (!stayOnPage) {
    resetToSearchVehicleView(state);
    state.vrmInputState = false;
    state.vrmInputInvalidFeedback = invalidFeedback;
  } else {
    if (state.BlockInputs.keeperSelectSingleSelect.answer === "Other") {
      state.BlockInputs.keeperStartDatePicker.invalidFeedback = invalidFeedback;
      state.BlockInputs.keeperStartDatePicker.state = false;
    } else {
      state.BlockInputs.keeperSelectSingleSelect.state = false;
      state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = invalidFeedback;
    }
    state.BlockInputs.searchRegButton.isProcessing = false;
    state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
  }
}

/** Handles the logic when the user submits the view for confirming their keeper dates dates */
export async function submitKeeperSelect(state: BlockMotorSpecsInstance) {
  try {
    state.BlockInputs.submitKeeperSelectButton.isProcessing = true;
    state.BlockInputs.keeperSelectSingleSelect.state = null;

    // Validate keeper selected
    if (!validateKeeperSelected(state)) return;

    // Check if "Other" is selected
    if (state.BlockInputs.keeperSelectSingleSelect.answer === "Other" && state.allowManualKeeperEntry) {
      // Validate the custom keeper start and end dates
      if (!validateCustomKeeperDates(state)) {
        state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
        return;
      }

      // Parse and set the custom keeper start and end dates
      const customKeeperStartDate = state.BlockInputs.keeperStartDatePicker.answer;
      const customKeeperEndDate = state.BlockInputs.keeperEndDatePicker.answer;
      state.vehicle.keeperStartDate = customKeeperStartDate;

      if (customKeeperEndDate && state.BlockInputs.currentOwnerSingleSelect.answer === "No") {
        state.vehicle.keeperEndDate = customKeeperEndDate;
        state.vehicle.isCurrentKeeper = false;
        state.vehicle.vehicleOwnershipStatus = "I have disposed of the vehicle";
      } else {
        state.vehicle.isCurrentKeeper = true;
        state.vehicle.vehicleOwnershipStatus = "I still own the vehicle";
      }

      state.vehicle.wereKeeperDatesManuallyEntered = true;
    } else {
      // Parse the keeper start and end dates from the keeperSelectSingleSelect for predefined keepers
      const selectedKeeperStartDate = state.BlockInputs.keeperSelectSingleSelect.answer;
      const selectedKeeper = state.vehicle.keeperChangeHistory.find(
        (keeperChange) => DateTime.fromJSDate(new Date(keeperChange.startDate)).toISO() === selectedKeeperStartDate
      );

      state.vehicle.keeperStartDate = selectedKeeper.startDate;

      state.vehicle.numberOfPreviousKeepers = selectedKeeper.previousKeepers;

      if (selectedKeeper.endDate) {
        state.vehicle.keeperEndDate = selectedKeeper.endDate;
        state.vehicle.isCurrentKeeper = false;
        state.vehicle.vehicleOwnershipStatus = "I have disposed of the vehicle";
      } else {
        state.vehicle.isCurrentKeeper = true;
        state.vehicle.vehicleOwnershipStatus = "I still own the vehicle";
      }
    }

    if (state.collectOwnershipPeriod) {
      let keeperEndDate;
      const keeperStartDate = DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate));

      if (state.vehicle.keeperEndDate && !state.vehicle.isCurrentKeeper) {
        keeperEndDate = DateTime.fromJSDate(new Date(state.vehicle.keeperEndDate));
      } else {
        keeperEndDate = DateTime.now();
      }

      const ownershipDuration = keeperEndDate.diff(keeperStartDate, ["months"]);
      state.vehicle.keeperOwnershipPeriodInMonths = Math.floor(ownershipDuration.months);
    }

    if (state.returnPriceEstimation) {
      //state.vehicle.manufacturerPurchasePrice

      const yearOfManufacture = parseInt(state.vehicle.yearOfManufacture);
      if (yearOfManufacture > 2008 && yearOfManufacture < 2020) {
        const priceEstimationData = {
          make: state.vehicle.make,
          model: state.vehicle.model,
          yearOfManufacture: yearOfManufacture,
          keeperStartDate: state.vehicle.keeperStartDate.toLocaleString(),
          keeperEndDate: state.vehicle.keeperEndDate?.toLocaleString(),
          isCurrentKeeper: state.vehicle.isCurrentKeeper,
          claimId: state.claimId,
          userId: state.userId,
          manufactureDate: state.vehicle.dateFirstRegistered,
        };

        console.log("priceEstimationData", priceEstimationData);
        try {
          const manufactureDate = DateTime.fromJSDate(new Date(priceEstimationData.manufactureDate));
          const keeperStartDate = DateTime.fromISO(priceEstimationData.keeperStartDate);
          console.log("keeperStartDate", keeperStartDate);
          console.log("manufactureDate", manufactureDate);
          const ownershipPeriodInMonths = Math.abs(manufactureDate.diff(keeperStartDate, "months").toObject().months);
          console.log("ownershipPeriodInMonths", ownershipPeriodInMonths);
          const [estimatedPurchasePrice, depreciatedPurchaseAmount] = calculateDepreciatedPrice(
            state.vehicle.manufacturerPurchasePrice,
            ownershipPeriodInMonths
          );

          console.log("estimatedPurchasePrice", estimatedPurchasePrice);
          console.log("depreciatedPurchaseAmount", depreciatedPurchaseAmount);

          const queryStartDate = DateTime.fromJSDate(new Date());
          const monthsSinceProduction = Math.abs(manufactureDate.diff(queryStartDate, "months").months);

          const [estimatedCurrentPrice, depreciatedCurrentAmount] = calculateDepreciatedPrice(
            state.vehicle.manufacturerPurchasePrice,
            monthsSinceProduction
          );

          let saleOwnershipPeriod: number;

          // If the claimant is the current keeper, use currentDate, otherwise use the keeperEndDate
          console.log(state.vehicle.isCurrentKeeper);
          if (state.vehicle.isCurrentKeeper) {
            const currentDate = DateTime.fromJSDate(new Date());
            const monthsSinceKeeperEndDate = Math.abs(manufactureDate.diff(currentDate, "months").months);
            saleOwnershipPeriod = monthsSinceKeeperEndDate;
          } else {
            const keeperEndDate = DateTime.fromISO(priceEstimationData.keeperEndDate);
            const monthsSinceKeeperEndDate = Math.abs(manufactureDate.diff(keeperEndDate, "months").months);
            saleOwnershipPeriod = monthsSinceKeeperEndDate;
          }

          const [estimatedSalePrice, depreciatedSaleAmount] = calculateDepreciatedPrice(
            state.vehicle.manufacturerPurchasePrice,
            saleOwnershipPeriod
          );

          const depreciatedDuringOwnershipValue = estimatedPurchasePrice - estimatedSalePrice;
          const depreciationData = {
            claimId: priceEstimationData.claimId,
            userId: priceEstimationData.userId,
            manufacturerPurchasePrice: state.vehicle.manufacturerPurchasePrice,
            estimatedPurchasePrice: Math.round(estimatedPurchasePrice),
            estimatedPurchasePriceRoundedFiveHundred: Math.round(estimatedPurchasePrice / 500) * 500,
            depreciatedPurchaseValue: Math.round(depreciatedPurchaseAmount),
            estimatedCurrentPrice: Math.round(estimatedCurrentPrice),
            depreciatedCurrentValue: Math.round(depreciatedCurrentAmount),
            estimatedSalePrice: Math.round(estimatedSalePrice),
            depreciatedSaleValue: Math.round(depreciatedSaleAmount),
            depreciatedDuringOwnershipValue: Math.round(depreciatedDuringOwnershipValue),
            manufactureDate: priceEstimationData.manufactureDate,
          };

          state.vehicle.estimatedPurchasePrice = depreciationData.estimatedPurchasePrice;
          state.vehicle.estimatedPurchasePriceRoundedFiveHundred =
            depreciationData.estimatedPurchasePriceRoundedFiveHundred;
          state.vehicle.depreciatedPurchaseValue = depreciationData.depreciatedPurchaseValue;
          state.vehicle.estimatedCurrentPrice = depreciationData.estimatedCurrentPrice;
          state.vehicle.depreciatedCurrentValue = depreciationData.depreciatedCurrentValue;
          state.vehicle.estimatedSalePrice = depreciationData.estimatedSalePrice;
          state.vehicle.depreciatedSaleValue = depreciationData.depreciatedSaleValue;
          state.vehicle.manufacturerPurchasePrice = depreciationData.manufacturerPurchasePrice;
          state.vehicle.depreciatedDuringOwnershipValue = depreciationData.depreciatedDuringOwnershipValue;
          state.vehicle.manufactureDate = depreciationData.manufactureDate;
          state.vehicle.yearOfManufacture = yearOfManufacture.toString();
        } catch (error) {
          console.error(error);
        }
      }
    }

    await saveVehicle(state);
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

function validateCustomKeeperDates(state: BlockMotorSpecsInstance): boolean {
  if (!state.BlockInputs.currentOwnerSingleSelect.answer) {
    state.BlockInputs.currentOwnerSingleSelect.invalidFeedback = "Please select an option.";
    state.BlockInputs.currentOwnerSingleSelect.state = false;
    return false;
  }

  let isValid = true;

  if (!state.BlockInputs.keeperStartDatePicker.answer) {
    state.BlockInputs.keeperStartDatePicker.invalidFeedback =
      "Please enter the date you became the owner of the vehicle";
    state.BlockInputs.keeperStartDatePicker.state = false;
    isValid = false;
  }

  if (!state.BlockInputs.keeperStartDatePicker.answer && state.BlockInputs.currentOwnerSingleSelect.answer === "No") {
    state.BlockInputs.keeperEndDatePicker.invalidFeedback =
      "Please enter the date you stopped being the owner of the vehicle";
    state.BlockInputs.keeperEndDatePicker.state = false;
    isValid = false;
  }

  // End date is optional, only validate if it's provided and must be after the start date
  if (
    state.BlockInputs.keeperEndDatePicker.answer &&
    state.BlockInputs.keeperEndDatePicker.answer <= state.BlockInputs.keeperStartDatePicker.answer
  ) {
    state.BlockInputs.keeperEndDatePicker.invalidFeedback =
      "Please enter a date after the date you became the owner of the vehicle";
    state.BlockInputs.keeperEndDatePicker.state = false;

    isValid = false;
  }

  return isValid;
}

/** Validates if a keeper has been selected */
function validateKeeperSelected(state: BlockMotorSpecsInstance): boolean {
  if (state.BlockInputs.keeperSelectSingleSelect.answer?.length === 0) {
    state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = state.uiMessages.noKeeperSelected;

    reject(state, state.uiMessages.noKeeperSelected);

    state.BlockInputs.keeperSelectSingleSelect.state = false;
    state.BlockInputs.submitKeeperSelectButton.isProcessing = false;

    return false;
  }
  return true;
}

/** Handles the logic when the user submits the view for confirming if the retured vehicle is correct */
export async function submitVehicleConfirmation(state: BlockMotorSpecsInstance) {
  try {
    if (state.BlockInputs.vehicleConfirmationSingleSelect.answer === "No") {
      state.uiToggles.showOwnershipDateQuestion = true;
      await state.$nextTick();
      state.uiToggles.showVehicleConfirmation = false;

      state.BlockInputs.vehicleConfirmationSingleSelect.answer = "";
      return;
    }

    state.BlockInputs.confirmVehicleText.html = `<div><div><h3><b>Vehicle Found</b></h3><h4>We found your vehicle, <b>${state.vehicle.make?.toUpperCase()}</b>, <b>${state.vehicle.model?.toUpperCase()}</b>, <b>${
      state.vehicle.yearOfManufacture
    }</b>, <b>${state.vehicle.fuelType}</b>.</h4></div></div>`;

    state.uiToggles.isVehicleSearchVisible = false;

    setupKeeperChangeSingleSelect(state);
    await state.$nextTick();
    state.uiToggles.showVehicleConfirmation = false;
    await state.$nextTick();
    state.uiToggles.isKeeperSelectVisible = true;
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

function setupKeeperChangeSingleSelect(state: BlockMotorSpecsInstance) {
  state.BlockInputs.keeperSelectSingleSelect.options = [
    ...state.vehicle.keeperChangeHistory.map((keeperChange) => {
      return DateTime.fromJSDate(new Date(keeperChange.startDate)).toISO();
    }),
    ...(state.allowManualKeeperEntry ? ["Other"] : []), // Add "Other" only if manual keeper entry is allowed
  ];
}

export function resetToSearchVehicleView(state: BlockMotorSpecsInstance) {
  // Clear fetched vehicle
  state.vehicle = null;

  // Clear previous answers
  state.vrm = "";
  state.ownershipDate = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.answer = "";
  state.BlockInputs.keeperSelectSingleSelect.answer = "";

  // Clear previous block states
  state.vrmInputState = null;
  state.BlockInputs.ownershipDatePicker.state = null;
  state.BlockInputs.vehicleConfirmationSingleSelect.state = null;
  state.BlockInputs.keeperSelectSingleSelect.state = null;

  // Clear previous block invalid feedbacks
  state.vrmInputInvalidFeedback = "";
  state.BlockInputs.keeperSelectSingleSelect.invalidFeedback = "";
  state.BlockInputs.ownershipDatePicker.invalidFeedback = "";
  state.BlockInputs.vehicleConfirmationSingleSelect.invalidFeedback = "";

  // Display the vehicle select
  state.uiToggles.isKeeperSelectVisible = false;
  state.uiToggles.showVehicleConfirmation = false;
  state.uiToggles.showOwnershipDateQuestion = false;
  state.$nextTick(() => {
    state.uiToggles.isVehicleSearchVisible = true;
  });

  // Reset the HTML text
  state.BlockInputs.confirmVehicleText.html = "";

  // Reset button loaders
  state.BlockInputs.submitVehicleConfirmationButton.isProcessing = false;
  state.BlockInputs.searchRegButton.isProcessing = false;
  state.BlockInputs.submitKeeperSelectButton.isProcessing = false;
}

/** Returns the correct set of funnel variables */
async function _getFunnelVariables(state: BlockMotorSpecsInstance) {
  let { data: funnelVariables } = await state.funnelsService.getFunnelVariables(state.funnelId);
  if (JONES_WHYTE_FUNNELS.includes(state.funnelId)) {
    // If we are on a Jones Whyte funnel and the vehicle is a Mercedes
    if (state.vehicle.make.toLowerCase().includes("mercedes")) {
      const { data: _funnelVariables } = await state.funnelsService.getFunnelVariables(
        Quantum.Funnels.JONES_WHYTE_MERCEDES_EMISSIONS_ID
      );

      funnelVariables = _funnelVariables;

      // If we are on a Jones Whyte funnel and the vehicle is not a Mercedes
    } else {
      const { data: _funnelVariables } = await state.funnelsService.getFunnelVariables(
        Quantum.Funnels.JONES_WHYTE_DIESEL_EMISSIONS_ID
      );

      funnelVariables = _funnelVariables;
    }
  }

  return funnelVariables;
}

async function userHasClaimsForVin(
  state: BlockMotorSpecsInstance,
  options?: { ignoreRejectedClaims?: boolean }
): Promise<boolean> {
  // const claimStatus = state.claimDataService.getArtefact("claimStatus");

  // comment out this if you want testing to be included
  // if (claimStatus === "testing") {
  //   return false;
  // }
  //Check that vin is not existing in this users claims
  const [claimsForUser, error] = await getClaimsByFunnelId(
    getFirebaseBackend().firestore(),
    state.userId,
    state.funnelId
  );

  if (state.claimDataService.getArtefact("claimStatus") === "testing") {
    return false;
  }

  if (error) {
    console.error("[motorspecs/methods.ts] Failed to get claims for user", error);
  }

  const funnelVariables = await _getFunnelVariables(state);

  const vinVariable = funnelVariables.find((variable) => variable.field === "vin");

  const existingClaim = claimsForUser.find((claim) => {
    if (claim.currentFunnelId !== state.funnelId) {
      return false;
    }
    // comment out this if you want testing to be included
    // if (claim.claimStatus === "testing" || claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
    //   return false;
    // }

    if (claim.claimId === state.claimId) {
      return false;
    }

    if (claim.claimStatus === "paused" || claim.claimStatus === "deleted") {
      return false;
    }

    if (claim.clientClaimProgress === "rejected" && options.ignoreRejectedClaims) {
      return false;
    }

    return claim?.[vinVariable.id] === state.vehicle.vin;
  });

  if (!existingClaim) {
    return false;
  }

  await state.infoModalService.fire("warning", {
    title: "You have already registered a claim for this vehicle",
    text: "It looks like you already have an active claim for this vehicle. Click resume to go to your existing claim.",
    cancelButtonText: "Cancel",
    confirmButtonText: "Resume",
    onConfirm: () => {
      if (state.navigationHideTrackPageClaimant) {
        getClaim(getFirebaseBackend().firestore(), state.userId, existingClaim.documentId).then(([_existingClaim]) => {
          // We just have to send the user to a specific url
          window.location.href =
            window.location.origin +
            `/form/${state.funnelId}/${_existingClaim.currentPageId}/${existingClaim.documentId}`;
        });
      } else {
        state.$router.push({ name: "Track", params: { claimId: existingClaim.documentId } }).then();
      }
    },
    onHide: () => {
      console.log("Closed");
    },
  });

  return true;
}

/** Saves the vehicle stored in the state to the claim data service */
export async function saveVehicle(state: BlockMotorSpecsInstance): Promise<boolean> {
  const funnelVariables = await _getFunnelVariables(state);

  const vehicle: (typeof state)["vehicle"] = {
    ...state.vehicle,
    enteredRegistration: state.vrm?.toUpperCase()?.replace(/\s/g, ""),
  };

  const hashedVehicle = await state.variablesService.hashData(vehicle, funnelVariables);

  for (const [key, value] of Object.entries(hashedVehicle)) {
    state.claimDataService.setArtefact(key, value);
  }

  const quantumFunnels = [
    Quantum.Funnels.DRUMMOND_MILLER_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.LEFEVRES_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.SLATER_GORDON_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.GILSON_GRAY_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID,
    Quantum.Funnels.STAGING_DIESEL_EMISSIONS_ID,
    GilsonGrey.Funnels.DIESEL_EMISSIONS_FUNNEL_ID,
  ];
  const slaterGordonFunnelId = Quantum.Funnels.SLATER_GORDON_DIESEL_EMISSIONS_ID;

  const MILBERG_DPF_FUNNEL_ID = "xIIzib4WMmdDg6Sa8R8B";
  const MILBERG_STAGING_FUNNEL_ID = "IOryuEcvgnAyf5jlTKe9";
  const THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID = Quantum.Funnels.THOMPSONS_SOLICITORS_DIESEL_EMISSIONS_ID;

  if (state.funnelId === Tlw.Funnels.DIESEL_EMISSIONS_FUNNEL_ID) {
    if (await userHasClaimsForVin(state, { ignoreRejectedClaims: true })) {
      reject(state, "Unfortunately, you have already registered a claim for this vehicle.");
      setValidationInvalidFeedback(state, "Unfortunately, you have already registered a claim for this vehicle.");

      return false;
    }

    const additionalStores: {
      tlwVehicleEligible?: boolean;
      tlwVehicleRejectionTag?: string;
      tlwVehicleRejectionExplanation?: string;
    } = {};

    let isEuro5: boolean;

    if (state.vehicle.ukvdEuroStatus) {
      isEuro5 = state.vehicle.ukvdEuroStatus.toLowerCase().includes("5");
    } else if (state.vehicle.euroStatus) {
      isEuro5 = state.vehicle.euroStatus.toLowerCase().includes("5");
    }

    const isEuro6PreRDE = state.vehicle.euroStatusEnhanced === "Euro 6 pre-RDE";

    if (!isEuro5 && !isEuro6PreRDE) {
      additionalStores.tlwVehicleEligible = false;
      additionalStores.tlwVehicleRejectionTag = `Incorrect Euro Status`;
      additionalStores.tlwVehicleRejectionExplanation = `Vehicle does not have Euro 5 or Euro 6 pre-RDE status.`;
    }

    // The vehicle must have been manufactured between 2009 and 2018
    if (parseInt(state.vehicle.yearOfManufacture) < 2009 || parseInt(state.vehicle.yearOfManufacture) > 2018) {
      additionalStores.tlwVehicleEligible = false;
      additionalStores.tlwVehicleRejectionTag = `Outside of Manufacture Dates`;
      additionalStores.tlwVehicleRejectionExplanation = `Vehicle was not manufactured between 2009 and 2018.`;
    }

    if (state.vehicle.fuelType.toLowerCase() !== "diesel") {
      additionalStores.tlwVehicleEligible = false;
      additionalStores.tlwVehicleRejectionTag = `Incorrect Fuel Type`;
      additionalStores.tlwVehicleRejectionExplanation = `Vehicle is not a Diesel.`;
    }

    const makeEligible = ["CITROEN", "PEUGEOT", "VOLKSWAGEN", "AUDI", "SKODA", "SEAT", "PORSHCE"].includes(
      state.vehicle.make?.toUpperCase()
    );

    if (!makeEligible) {
      additionalStores.tlwVehicleEligible = false;
      additionalStores.tlwVehicleRejectionTag = `Incorrect Make`;
      additionalStores.tlwVehicleRejectionExplanation = `Vehicle is not produced by an eligible manufacturer.`;
    }

    // TODO: This check will be renabled once we have the correct data from UKVD.
    // if (
    //   (state.vehicle.make?.toUpperCase() === "RENAULT" || state.vehicle.make?.toUpperCase() === "NISSAN") &&
    //   state.vehicle.mvrisTypeApprovalCategory !== undefined &&
    //   state.vehicle.ukvdTypeApproval !== undefined
    // ) {
    //   const eligibleTypeApprovals = ["N", "M"];

    //   const eligibleUkvdTypeApproval = eligibleTypeApprovals.find((typeApproval) =>
    //     state.vehicle?.ukvdTypeApproval?.toUpperCase()?.includes(typeApproval)
    //   );
    //   const eligibleMvrisTypeApproval = eligibleTypeApprovals.find((typeApproval) =>
    //     state.vehicle?.mvrisTypeApprovalCategory?.toUpperCase()?.includes(typeApproval)
    //   );

    //   if (
    //     !eligibleUkvdTypeApproval &&
    //     !eligibleMvrisTypeApproval &&
    //     state.vehicle.mvrisTypeApprovalCategory !== undefined &&
    //     state.vehicle.ukvdTypeApproval !== undefined
    //   ) {
    //     additionalStores.tlwVehicleEligible = false;
    //     additionalStores.tlwVehicleRejectionTag = `Incorrect Type Approval`;
    //     additionalStores.tlwVehicleRejectionExplanation = `Vehicle does not have the correct type approval.`;
    //   }
    //   console.log(">>> SPECS ADDITIONAL STORES", additionalStores);
    // }

    const volkswagenGroupMakes = ["VOLKSWAGEN", "AUDI", "SEAT", "SKODA", "PORSCHE"];

    if (volkswagenGroupMakes.includes(state.vehicle.make?.toUpperCase())) {
      // If the vehicle does not have an EA288 engine
      if (state.vehicle.emEngineFamily !== "EA288") {
        const engineIsVConfiguration = state.vehicle.eaEngineConfig?.toUpperCase()?.includes("V");
        if (!engineIsVConfiguration) {
          additionalStores.tlwVehicleEligible = false;
          additionalStores.tlwVehicleRejectionTag = `Incorrect Engine Family`;
          additionalStores.tlwVehicleRejectionExplanation = `Vehicle does not have the correct engine family.`;
        }
      }
    }

    if (additionalStores.tlwVehicleEligible !== false) {
      additionalStores.tlwVehicleEligible = true;
    }

    // Store the data on the claim
    if (Object.keys(additionalStores).length > 0) {
      // First we need to hash the data
      const hashedAdditionalStores = await state.variablesService.hashData(additionalStores, funnelVariables);

      for (const [key, value] of Object.entries(hashedAdditionalStores)) {
        state.claimDataService.setArtefact(key, value);
      }
    }
  }
  const pcpFunnels = [
    SlaterGordon.Funnels.MISSOLD_CAR_FINANCE_FUNNEL_ID,
    Quantum.Funnels.SLATER_GORDON_MOTOR_FINANCE_ID,
    Dgm.Funnels.MISSOLD_CAR_FINANCE_FUNNEL_ID,
    Quantum.Funnels.THOMPSONS_SOLICITORS_MOTOR_FINANCE_ID,
    Quantum.Funnels.DRUMMOND_MILLER_MOTOR_FINANCE_ID,
    Quantum.Funnels.JONES_WHYTE_MOTOR_FINANCE_ID,
    Quantum.Funnels.LEFEVRES_MOTOR_FINANCE_ID,
  ];
  if (pcpFunnels.includes(state.funnelId)) {
    if (await userHasClaimsForVin(state)) {
      reject(state, "Unfortunately, you have already registered a claim for this vehicle.");
      setValidationInvalidFeedback(state, "Unfortunately, you have already registered a claim for this vehicle.");

      return false;
    }

    // If the vehicle was purchased after 28th January 2021
    const purchaseFrom = DateTime.fromISO("2021-01-28");
    const keeperStartDate = DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate));
    if (keeperStartDate.toMillis() >= purchaseFrom.toMillis()) {
      reject(
        state,
        "Unfortunately, to register a claim, you must have purchased your vehicle before 28th January 2021."
      );
      setValidationInvalidFeedback(
        state,
        "Unfortunately, to register a claim, you must have purchased your vehicle before 28th January 2021.",
        true
      );
      return false;
    }
  }
  if (state.funnelId === MILBERG_DPF_FUNNEL_ID || state.funnelId === MILBERG_STAGING_FUNNEL_ID) {
    if (await userHasClaimsForVin(state)) {
      reject(state, "Unfortunately, you have already registered a claim for this vehicle.");
      setValidationInvalidFeedback(state, "Unfortunately, you have already registered a claim for this vehicle.");

      return false;
    }

    const isJaguar = state.vehicle.make?.toUpperCase().includes("JAGUAR");
    const isLandRover = state.vehicle.make?.toUpperCase().includes("LAND ROVER");
    const isJaquarOrLandRover = isJaguar || isLandRover;

    if (!isJaquarOrLandRover) {
      reject(state, "Unfortunately, your vehicle is not eligible for this claim.");
      setValidationInvalidFeedback(state, "Unfortunately, your vehicle is not eligible for this claim.");
      return false;
    }

    if (!state.vehicle.dpfAffected) {
      reject(state, "Unfortunately, your vehicle is not affected by the Diesel Particulate Filter (DPF) issue.");
      setValidationInvalidFeedback(
        state,
        "Unfortunately, your vehicle is not affected by the Diesel Particulate Filter (DPF) issue."
      );

      return false;
    }

    const keeperStartDate = DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate));
    const purchaseFrom = DateTime.fromISO("2018-07-01");

    // If the vehicle was purchased before 1st July 2018
    if (keeperStartDate.toMillis() < purchaseFrom.toMillis()) {
      reject(state, "Unfortunately, to register a claim, you must have purchased your vehicle after July 2018.");
      setValidationInvalidFeedback(
        state,
        "Unfortunately, to register a claim, you must have purchased your vehicle after July 2018.",
        true
      );
      return false;
    }

    // If the vehicle was purchased after 1st July 2018, then it is at Tier 1
    const category1StartDate = DateTime.fromISO("2018-07-01");

    if (keeperStartDate.toMillis() >= category1StartDate.toMillis()) {
      state.claimDataService.setArtefact("vehicleTier", "Tier 1");
    }

    // If the vehicle was purchased between 1st October 2014 and 31st December 2017
    // Tag the claim as category 2
    // ! Technically this code will never run, since clients are required to have purchased their vehicle after July 2018
    const category2StartDate = DateTime.fromISO("2014-10-01");
    const category2EndDate = DateTime.fromISO("2017-12-31");
    if (
      keeperStartDate.toMillis() >= category2StartDate.toMillis() &&
      keeperStartDate.toMillis() <= category2EndDate.toMillis()
    ) {
      state.claimDataService.setArtefact("vehicleTier", "Tier 2");
    }
  }

  if (quantumFunnels.includes(state.funnelId)) {
    if (await userHasClaimsForVin(state)) {
      reject(state, "Unfortunately, you have already registered a claim for this vehicle.");
      setValidationInvalidFeedback(state, "Unfortunately, you have already registered a claim for this vehicle.");

      return false;
    }

    // If the vehicle is not a Diesel
    if (state.vehicle.fuelType.toLowerCase() !== "diesel") {
      reject(state, state.uiMessages.notDiesel);
      setValidationInvalidFeedback(state, state.uiMessages.notDiesel);
      return false;
    }

    // If the vehicle was not manufactured between 2009 and 2019
    if (parseInt(state.vehicle.yearOfManufacture) < 2009 || parseInt(state.vehicle.yearOfManufacture) > 2019) {
      reject(state, state.uiMessages.notDiesel);
      setValidationInvalidFeedback(state, state.uiMessages.quantum2009To2019);
      return false;
    }

    if (state.funnelId === slaterGordonFunnelId) {
      setupDefenderAndContactEmail(state, true);
    } else {
      setupDefenderAndContactEmail(state, false);
    }
  }

  // If we are on a Jones Whyte Funnel
  if (JONES_WHYTE_FUNNELS.includes(state.funnelId)) {
    // If the vehicle is not a Diesel
    if (state.vehicle.fuelType.toLowerCase() !== "diesel") {
      reject(state, state.uiMessages.notDiesel);
      setValidationInvalidFeedback(state, state.uiMessages.notDiesel);
      return false;
    }

    if (state.vehicle.make.toLowerCase().includes("mercedes")) {
      // If the vehicle was not manufactured between 2008 and 2018
      if (parseInt(state.vehicle.yearOfManufacture) < 2008 || parseInt(state.vehicle.yearOfManufacture) > 2018) {
        reject(state, state.uiMessages.notDiesel);
        setValidationInvalidFeedback(state, state.uiMessages.jonesWhyte2008To2018);
        return false;
      }
    } else {
      // If the vehicle was not manufactured between 2009 and 2019

      if (parseInt(state.vehicle.yearOfManufacture) < 2009 || parseInt(state.vehicle.yearOfManufacture) > 2019) {
        reject(state, state.uiMessages.notDiesel);
        setValidationInvalidFeedback(state, state.uiMessages.quantum2009To2019);
        return false;
      }
    }

    setupDefenderAndContactEmail(state, false);
  }

  if (state.funnelId === Provenio.Funnels.MIS_SOLD_CAR_FINANCE_ID) {
    const isMoreThanJuly2019 =
      (state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year >= 2019 &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).month > 7) ||
      (state.vehicle.keeperStartDate && DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year >= 2020);

    // ! Not using this logic anymore
    // If there are more than 1 previous keepers
    // if (state.vehicle.numberOfPreviousKeepers && state.vehicle.numberOfPreviousKeepers >= 2) {
    //   reject(state, state.uiMessages.provenioOriginalOwner);
    //   setValidationInvalidFeedback(state, state.uiMessages.provenioOriginalOwner, true);
    //   return false;
    // }
    console.log(
      "Keeperstart",
      state.vehicle.keeperStartDate,
      isMoreThanJuly2019,
      state.vehicle.keeperStartDate && DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year < 2013
    );
    // If the vehicle was purchased before 2013 or after 2019
    if (
      (state.vehicle.keeperStartDate && DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year < 2013) ||
      isMoreThanJuly2019
    ) {
      reject(state, state.uiMessages.provenioPurchasedBetween2013And2019);
      setValidationInvalidFeedback(state, state.uiMessages.provenioPurchasedBetween2013And2019, true);
      return false;
    }

    console.log(">>> NUMBER OF KEEPERS", state.vehicle.numberOfPreviousKeepers);
  }

  if (
    state.funnelId === Milberg.Funnels.VAUXHALL_DIESEL_EMISSIONS_FUNNEL_ID ||
    state.funnelId === "VabLrfVIHH3uYOVIpHyd"
  ) {
    // If the vehicle's make is not a mercedes or vauxhall (lowercase)
    if (!["mercedes", "vauxhall"].includes(state.vehicle.make.toLowerCase())) {
      reject(state, state.uiMessages.milbergNotVauxhallOrMercedes);
      setValidationInvalidFeedback(state, state.uiMessages.milbergNotVauxhallOrMercedes);
      return false;
    }

    // If the vehicle's fuel type does not include Diesel
    if (!state.vehicle.fuelType.toLowerCase().includes("diesel")) {
      reject(state, state.uiMessages.milbergNotDiesel);
      setValidationInvalidFeedback(state, state.uiMessages.milbergNotDiesel);
      return false;
    }

    // ! If the vehicle is a Vauxhall
    if (state.vehicle.make.toLowerCase().includes("vauxhall")) {
      // If the start date of keeper is defined and is in the year 2017
      if (
        state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year === 2017 &&
        // Ensure that the month is greater than July
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).month > 7
      ) {
        if (parseFloat(state.vehicle.yearOfManufacture) < 2013) {
          reject(state, state.uiMessages.milberg4YearsAfterManufacture);
          setValidationInvalidFeedback(state, state.uiMessages.milberg4YearsAfterManufacture);
          return false;
        }
      } else if (
        state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year === 2018
      ) {
        if (parseFloat(state.vehicle.yearOfManufacture) < 2014) {
          reject(state, state.uiMessages.milberg4YearsAfterManufacture);
          setValidationInvalidFeedback(state, state.uiMessages.milberg4YearsAfterManufacture);
          return false;
        }
      } else if (
        state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year === 2019
      ) {
        if (parseFloat(state.vehicle.yearOfManufacture) < 2015) {
          reject(state, state.uiMessages.milberg4YearsAfterManufacture);
          setValidationInvalidFeedback(state, state.uiMessages.milberg4YearsAfterManufacture);
          return false;
        }
      } else {
        reject(state, state.uiMessages.milbergVehicleNotOwnedIn2017);
        setValidationInvalidFeedback(state, state.uiMessages.milbergVehicleNotOwnedIn2017, true);
        return false;
      }
    }

    // If the vehicle is a Mercedes
    if (state.vehicle.make.toLowerCase().includes("mercedes")) {
      // If the start date of keeper is defined and is not in the year 2017, 2018 or 2019.
      if (
        state.vehicle.keeperStartDate &&
        ![2017, 2018, 2019].includes(DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year)
      ) {
        reject(state, state.uiMessages.milbergVehicleNotOwnedIn2017);
        setValidationInvalidFeedback(state, state.uiMessages.milbergVehicleNotOwnedIn2017, true);
        return false;
      }

      // If the vehicle was purchaed in 2017 and before July
      if (
        state.vehicle.keeperStartDate &&
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year === 2017 &&
        // Ensure that the month is greater than July
        DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).month < 7
      ) {
        reject(state, state.uiMessages.milbergVehicleNotOwnedIn2017);
        setValidationInvalidFeedback(state, state.uiMessages.milbergVehicleNotOwnedIn2017, true);
        return false;
      }
    }
  }

  // If we are on the lanier funnel and the keeper start date is after 2020
  if (
    [Lanier.Funnels.RENAULT_DIESEL_EMISSIONS_ID, Lanier.Funnels.STAGING_RENAULT_DIESEL_EMISSIONS_ID].includes(
      state.funnelId
    )
  ) {
    if (state.vehicle.keeperStartDate && DateTime.fromJSDate(new Date(state.vehicle.keeperStartDate)).year > 2020) {
      reject(state, state.uiMessages.lanierNotPurchasedBefore2020);
      setValidationInvalidFeedback(state, state.uiMessages.lanierNotPurchasedBefore2020, true);
      return false;
    }
  }

  // If the vehicle is nearly eligible and is a S&G client

  state.$store.dispatch("events/fire", { name: state.eventValues.next });

  return true;
}

function setupDefenderAndContactEmail(state: BlockMotorSpecsInstance, setContactEmail: boolean) {
  if (state.vehicle.emCode === 8) {
    state.claimDataService.setArtefact("defender", "Volkswagen Group");
    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "VW2ScotlandClaims@slatergordon.co.uk");
    }
    return;
  }

  // If make contains Citroen
  if (state.vehicle.make.toLowerCase().includes("citroen")) {
    state.claimDataService.setArtefact("defender", "Citroen");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "citroenscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Fiat
  if (state.vehicle.make.toLowerCase().includes("fiat")) {
    state.claimDataService.setArtefact("defender", "Fiat");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "fiatscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Jaguar Land Rover
  if (
    state.vehicle.make.toLowerCase().includes("jaguar") ||
    state.vehicle.make.toLowerCase().includes("land rover") ||
    state.vehicle.make.toLowerCase().includes("landrover") ||
    state.vehicle.make.toLowerCase().includes("rover")
  ) {
    state.claimDataService.setArtefact("defender", "Jaguar Land Rover");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "jaguarscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Nissan
  if (state.vehicle.make.toLowerCase().includes("nissan")) {
    state.claimDataService.setArtefact("defender", "Nissan");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "nissanscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Peugot
  if (state.vehicle.make.toLowerCase().includes("peugeot")) {
    state.claimDataService.setArtefact("defender", "Peugeot");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "peugeotscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Renault
  if (state.vehicle.make.toLowerCase().includes("renault")) {
    state.claimDataService.setArtefact("defender", "Renault");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "renaultscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Vauxhall
  if (state.vehicle.make.toLowerCase().includes("vauxhall")) {
    state.claimDataService.setArtefact("defender", "Vauxhall");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "vauxhallscotlandclaims@slatergordon.co.uk");
    }
  }

  // If make contains Volvo
  if (state.vehicle.make.toLowerCase().includes("volvo")) {
    state.claimDataService.setArtefact("defender", "Volvo");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "volvoscotlandclaims@slatergordon.co.uk");
    }
  }

  if (state.vehicle.make.toLowerCase().includes("bmw")) {
    state.claimDataService.setArtefact("defender", "BMW");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "bmwscotlandclaims@slatergordon.co.uk");
    }
  }

  // Hyundai / Kia
  if (state.vehicle.make.toLowerCase().includes("hyundai") || state.vehicle.make.toLowerCase().includes("kia")) {
    state.claimDataService.setArtefact("defender", "Hyundai / Kia");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "HyundaiScotlandClaims@slatergordon.co.uk");
    }
  }

  // Ford
  if (state.vehicle.make.toLowerCase().includes("ford")) {
    state.claimDataService.setArtefact("defender", "Ford");

    if (setContactEmail) {
      state.claimDataService.setArtefact("contactEmail", "FordScotlandClaims@slatergordon.co.uk");
    }
  }
}

export async function searchVehicle(state: BlockMotorSpecsInstance, forceHistoricalVinLookup: boolean = false) {
  try {
    resetSearchVehicleUiToggles(state);

    // Breaks if we were unable to validate the entered VRM
    if (!validateVrm(state)) {
      state.uiToggles.isSearchingByQueryParameters = false;
      return;
    }

    let ownershipDate: DateTime;
    if (forceHistoricalVinLookup) {
      ownershipDate = DateTime.fromJSDate(new Date(state.ownershipDate));
    }

    // Remove any spaces from the VRM
    const vrm = state.vrm.replace(/\s/g, "");

    const { data: motorSpecsResponse } = await getVehicleFromMotorSpecs(
      state,
      vrm,
      forceHistoricalVinLookup,
      ownershipDate
    );

    if (!(await parseMotorSpecsResponse(motorSpecsResponse, state, forceHistoricalVinLookup))) {
      state.uiToggles.isSearchingByQueryParameters = false;
      return;
    }

    state.BlockInputs.searchRegButton.isProcessing = false;
    state.vrmInputState = null;
    state.uiToggles.isSearchingByQueryParameters = false;
  } catch (error) {
    tryCatchHandler(state, error);
  }
}

/** Fetches the vehicle and updates the interface in the event of an error */
async function parseMotorSpecsResponse(
  motorSpecsResponse: ClaimsGateMotorSpecsGetVehicleResponse | ClaimsGateFunctionReturnedError,
  state: BlockMotorSpecsInstance,
  forceHistoricalVinLookup: boolean = false
): Promise<boolean> {
  console.log("isClaimsGateFunctionReturnedError", isClaimsGateFunctionReturnedError(motorSpecsResponse));
  if (isClaimsGateFunctionReturnedError(motorSpecsResponse)) {
    // If the API returned a MotorSpecsVehicleNotFound error then the vehicle either
    // a) could not be found
    // b) is affected by reverse cherished transfer and we require a date when the user owned the vehicle to find it
    const isMotorSpecsVehicleNotFoundError =
      motorSpecsResponse.error.id === new ClaimsGateErrors.MotorSpecsVehicleNotFound().id ||
      motorSpecsResponse.error.id === "MotorSpecsVehicleNotFound";

    if (isMotorSpecsVehicleNotFoundError) {
      // If no vehicle was found then collect the ownership date for a more accurate search
      if (!forceHistoricalVinLookup) {
        state.uiToggles.showOwnershipDateQuestion = true;
        await state.$nextTick();
        state.uiToggles.isVehicleSearchVisible = false;
      } else {
        resetToSearchVehicleView(state);
        state.vrmInputState = false;
        state.vrmInputInvalidFeedback = state.uiMessages.noVehicleFound;
        reject(state, state.uiMessages.noVehicleFound);
        state.BlockInputs.searchRegButton.isProcessing = false;
      }
    } else {
      state.vrmInputState = false;
      state.vrmInputInvalidFeedback = state.uiMessages.errorFindingVehicle;
      reject(state, state.uiMessages.errorFindingVehicle);
      state.BlockInputs.searchRegButton.isProcessing = false;
    }

    return false;
  }

  const vehicle: MotorSpecsPartialVehicleStore = {
    emCode: motorSpecsResponse.emCode,
    emEngineFamily: motorSpecsResponse.emCodeDetails?.engineFamily,
    make: motorSpecsResponse.vehicle.combined.make,
    model: motorSpecsResponse.vehicle.combined.model,
    version: motorSpecsResponse.vehicle.combined.version,
    colour: motorSpecsResponse.vehicle.combined.colour,
    vin: motorSpecsResponse.vehicle.combined.vin,
    vehicleRegistration: motorSpecsResponse.vehicle.combined.registration,
    fuelType: motorSpecsResponse.vehicle.combined.fuel,
    numberOfCylinders: motorSpecsResponse.vehicle.mvris.cylinderCount,
    engineCapacity: motorSpecsResponse.vehicle.combined.cc,
    engineSize: motorSpecsResponse.vehicle.mvris.engineSize,
    dateFirstRegistered: motorSpecsResponse.vehicle.combined.regDate,
    bhp: motorSpecsResponse.vehicle.combined.powerBHP,
    torqueNm: motorSpecsResponse.vehicle.mvris.torqueNm,
    engineNumber: motorSpecsResponse.vehicle.dvla.engineNumber,
    engineModelCode: motorSpecsResponse.vehicle.dvla.engineCode ?? motorSpecsResponse.vehicle.mvris.engineDescription,
    engineLocation: motorSpecsResponse.vehicle.mvris.engineLocation,
    euroStatus: motorSpecsResponse.vehicle.mvris.euroStatus?.toString(),
    euroStatusEnhanced: motorSpecsResponse.emCodeDetails?.euroStageEnhanced,
    numberOfDoors: motorSpecsResponse.vehicle.combined.doors,
    series: motorSpecsResponse.vehicle.mvris.vehicleSeries,
    maximumPowerInKw: motorSpecsResponse.vehicle.combined.powerKW ?? motorSpecsResponse.vehicle.mvris.powerKw,
    vinLast5: motorSpecsResponse.vehicle.combined.vin.substring(motorSpecsResponse.vehicle.combined.vin.length - 5),
    yearOfManufacture: DateTime.fromISO(
      motorSpecsResponse.vehicle?.combined?.manufDate ?? motorSpecsResponse.vehicle?.combined?.regDate
    )
      .get("year")
      .toString(),
    keeperChangeHistory: motorSpecsResponse.keepersHistory,
    mercedesEmissionsRecallMandatoryStatus:
      motorSpecsResponse.mercedesRecallComputedFields?.mercedesEmissionsRecallMandatoryStatus,
    mercedesEmissionsRecallEligibleForExclusion:
      motorSpecsResponse.mercedesRecallComputedFields?.mercedesEmissionsRecallEligibleForExclusion,
    mercedesEmissionsRecallAccomplished:
      motorSpecsResponse.mercedesRecallComputedFields?.mercedesEmissionsRecallAccomplished,
    manufacturerPurchasePrice: motorSpecsResponse.manufacturerPurchasePrice,
    purchasePriceInformation: motorSpecsResponse.purchasePriceInformation,
    recalls: motorSpecsResponse.recalls,
    dpfAffected: motorSpecsResponse.dpf?.isEligible,
    dpfConfidenceScoreReason: motorSpecsResponse.dpf?.reasons,
    dpfEngineMake: motorSpecsResponse.dpf?.engineMake,
    dpfPlatform: motorSpecsResponse.dpf?.series,
    ukvdEuroStatus: motorSpecsResponse.ukvdEuroStatus,
    ukvdTypeApproval: motorSpecsResponse.ukvdTypeApproval,
    mvrisTypeApprovalCategory: motorSpecsResponse.mvrisTypeApprovalCategory,
    vehicleDataSource: "MotorCheck",
    manufactureDate: DateTime.fromISO(
      motorSpecsResponse.vehicle?.combined?.manufDate ?? motorSpecsResponse.vehicle?.combined?.regDate
    ).toISO(),
    eaEngineConfig: motorSpecsResponse.engineConfig,
  };

  const compactedVehicle: MotorSpecsPartialVehicleStore = omitBy(vehicle, isNil);

  // Using a vue setter to prevenet reactivity issues
  state.$set(state, "vehicle", compactedVehicle);

  // Show the vehicle confirmation step

  state.uiToggles.showVehicleConfirmation = true;
  await state.$nextTick();

  state.uiToggles.isVehicleSearchVisible = false;
  await state.$nextTick();

  if (forceHistoricalVinLookup) {
    state.uiToggles.showOwnershipDateQuestion = false;
  }

  state.BlockInputs.searchRegButton.isProcessing = false;

  // If no error was returned, then we can save the vehicle data to our database

  return true;
}

/** Returns a boolean indcating if the entered vrm is valid */
function validateVrm(state: BlockMotorSpecsInstance): boolean {
  if (state.vrm.length === 0) {
    state.vrmInputState = false;
    state.vrmInputInvalidFeedback = state.uiMessages.enterReg;
    reject(state, state.uiMessages.enterReg);
    state.BlockInputs.searchRegButton.isProcessing = false;
    return false;
  }

  return true;
}

/** Resets the states of inputs and processing state when on the search vehicle view */
function resetSearchVehicleUiToggles(state: BlockMotorSpecsInstance) {
  state.BlockInputs.searchRegButton.isProcessing = true;
  state.BlockInputs.ownershipDatePicker.state = null;
  state.vrmInputState = null;
}

/** Type guard to check if the response returned from getVehicleFromMotorSpecs is a ClaimsGateFunctionReturnedError */
function isClaimsGateFunctionReturnedError(
  response: ClaimsGateMotorSpecsGetVehicleResponse | ClaimsGateFunctionReturnedError
): response is ClaimsGateFunctionReturnedError {
  console.log("isClaimsGateReturnedError", response, response as ClaimsGateFunctionReturnedError);
  return (response as ClaimsGateFunctionReturnedError)?.error?.id !== undefined;
}

/** Fetches a given vehicle from the MotorSpecs backend */
async function getVehicleFromMotorSpecs<F = "getVehicleFromMotorSpecs">(
  state: BlockMotorSpecsInstance,
  vid: string,
  forceHistoricalVinLookup: boolean = false,
  date?: DateTime
): Promise<{ data?: ClaimsGateMotorSpecsGetVehicleResponse | ClaimsGateFunctionReturnedError }> {
  return onCallGateway<"getVehicleFromMotorSpecs">({
    functionName: "getVehicleFromMotorSpecs",
    data: {
      vid,
      options: {
        returnMercedesRecallData: state.returnMercedesRecallData,
        returnDpfData: state.returnDpfData,
        returnSupplementaryDataFromUkvd: state.returnSupplementaryDataFromUkvd,
        returnPriceEstimation: state.returnPriceEstimation,
        returnSupplementaryVwDataFromEa: state.returnSupplementaryVwDataFromEa,
      },

      claimId: state.claimId,

      funnelId: state.funnelId,
      pageId: state.pageId,
      forceHistoricalVinLookup,
      date: date?.toISO(),
    },
  });
}

function validateOwnershipDate(state: BlockMotorSpecsInstance): boolean {
  if (!state.ownershipDate) {
    state.BlockInputs.ownershipDatePicker.state = false;
    state.BlockInputs.ownershipDatePicker.invalidFeedback = state.uiMessages.noOwnershipDateSelected;
    reject(state, state.uiMessages.noOwnershipDateSelected);
    state.BlockInputs.searchRegButton.isProcessing = false;
    return false;
  }

  const ownershipDate = DateTime.fromJSDate(new Date(state.ownershipDate)).setZone("utc");
  const currentDate = DateTime.now().setZone("utc");

  if (ownershipDate > currentDate) {
    state.BlockInputs.ownershipDatePicker.state = false;
    state.BlockInputs.ownershipDatePicker.invalidFeedback = state.uiMessages.ownershipDateInFuture;
    state.BlockInputs.searchRegButton.isProcessing = false;
    return false;
  }

  return true;
}

// Create a generic try catch handler which will log the error and reset the view to the search vehicle view
function tryCatchHandler(state: BlockMotorSpecsInstance, error: Error) {
  console.error(error);
  resetToSearchVehicleView(state);

  state.vrmInputState = false;
  state.vrmInputInvalidFeedback = state.uiMessages.errorFindingVehicle;
  reject(state, state.uiMessages.errorFindingVehicle);

  fireMotorSpecsApiErrorEvent(state);
}
